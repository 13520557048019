<template src="./index.html" />

<script>
import {
  BButton,
  BCol,
  BRow,
  BForm,
  BBreadcrumb,
  BCard,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  VBTooltip,
  ModalPlugin,
  BButtonGroup,
  BOverlay, BImg,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import vSelect from 'vue-select'
import { STUDENT_CLASS_STATUSES } from '@/const/status'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import '@core/scss/vue/libs/vue-good-table.scss'
import { getUser } from '@/auth/utils'
import { PermissionCode, ResourceCode } from '@/const/code'
import { hasPermissionForResource } from '@/utils'

const user = getUser()
export default {
  name: 'SearchStudent',
  components: {
    BImg,
    VueGoodTable,
    BButton,
    BCol,
    BRow,
    BForm,
    BBreadcrumb,
    BCard,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    ModalPlugin,
    VBTooltip,
    vSelect,
    BButtonGroup,
    BOverlay,
  },
  props: {},
  data() {
    return {
      loading: false,
      serverParams: {
        columnFilters: {},
        filterSearch: {
          trainingSystemId: '',
          courseId: '',
          classId: '',
          departmentId: '',
          majorId: '',
          consultantId: '',
          status: 1,
          name: '',
          code: '',
        },
        sort: [
          {
            field: '',
            type: '',
          },
        ],
        page: 1,
        perPage: 10,
      },
      table: {
        fields: [
          {
            field: 'code',
            label: 'Mã sinh viên',
            sortable: false,
            type: 'string',
          },
          {
            field: 'name', label: 'Tên học sinh', sortable: true, type: 'string',
          },
          {
            field: 'className',
            label: 'Lớp cố định',
            sortable: false,
            type: 'string',
          },
          {
            field: 'courseName', label: 'Khóa học', sortable: false, type: 'string',
          },
          {
            field: 'majorName', label: 'Ngành học', sortable: false, type: 'string',
          },
          {
            field: 'consultantName', label: 'Cố vấn học tập', sortable: false, type: 'string',
          },
          {
            field: 'status', label: 'Trạng thái', sortable: false, tdClass: 'text-center', thClass: ' text-center',
          },
          {
            field: 'action', label: '', sortable: false, tdClass: 'text-center',
          },
        ],
        items: [],
        totalRows: 0,
      },
      dataSendClass: {},
      optMajor: [],
      optStatus: [],
    }
  },
  computed: {
    ...mapGetters({
      classes: 'dropdown/classes',
      total: 'studentClass/totalRows',
      statuses: 'classes/statuses',
      majors: 'dropdown/majors',
      trainingSystems: 'dropdown/trainingSystems',
      courses: 'dropdown/courses',
      departments: 'dropdown/departments',
      teachers: 'dropdown/teachers',
      dataLists: 'studentClass/dataLists',
    }),
    provisionScoreExportable() {
      return hasPermissionForResource(PermissionCode.READ, ResourceCode.PROVISION_SCORE)
    },
  },
  async created() {
    // init data FROM API
    this.optStatus = STUDENT_CLASS_STATUSES
    await this.getInitData()
    // await this.getData(this.serverParams)
  },
  methods: {
    ...mapActions({
      readMajors: 'dropdown/getMajors',
      getTrainingSystem: 'dropdown/getTrainingSystems',
      getCourse: 'dropdown/getCourses',
      getDepartments: 'dropdown/getDepartments',
      getTeachers: 'dropdown/getTeachers',
      getClasses: 'dropdown/getClasses',
      searchStudent: 'studentClass/searchStudent',
      exportProvisionScorePdf: 'studentClass/exportProvisionScorePdf',
    }),

    updateParams(newProps) {
      this.serverParams = { ...this.serverParams, ...newProps }
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage })
      this.loadItems()
    },

    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage })
      this.loadItems()
    },

    onSortChange(params) {
      this.updateParams({
        sort: [
          {
            type: params[0].type,
            field: params[0].field,
          },
        ],
      })
      this.loadItems()
    },

    onColumnFilter(params) {
      this.updateParams(params)
      this.loadItems()
    },

    // load items is what brings back the rows from server
    loadItems() {
      this.btnSearchClick()
    },

    showToast(title, icon, variant, text) {
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title,
          icon,
          variant,
          text,
        },
      })
    },

    async btnSearchClick() {
      this.loading = true
      await this.getData(this.serverParams)
    },

    openModal() {
      this.dataSendClass = {}
      this.$refs['modal-class'].show()
    },

    columnFilterFn() {},

    // getStatusName(id) {
    //   return STATUSES.find(status => status.value === id).label
    // },

    async getInitData() {
      await this.getTrainingSystem({ organizationId: user.orgId })
      await this.getDepartments({ organizationId: user.orgId })
    },

    async onChangeTrainingSystem(id) {
      this.serverParams.filterSearch.courseId = ''
      this.serverParams.filterSearch.classId = ''
      await this.getCourse({ trainingSystemId: id })
    },

    async onChangeDepartment(id) {
      this.serverParams.filterSearch.majorId = ''
      this.serverParams.filterSearch.teacherId = ''
      await this.readMajors({ departmentId: id })
      await this.getTeachers({ departmentId: id })
    },
    async onChangeCourse(id) {
      this.serverParams.filterSearch.classId = ''
      await this.getClasses({
        majorId: this.serverParams.filterSearch.majorId,
        courseId: id,
      })
    },

    async onChangeMajor(id) {
      this.serverParams.filterSearch.classId = ''
      await this.getClasses({
        majorId: id,
        courseId: this.serverParams.filterSearch.courseId,
      })
    },

    async getData(params) {
      this.loading = true
      try {
        await this.searchStudent({
          currentPage: params.page,
          itemsPerPage: params.perPage,
          trainingSystemId: params.filterSearch.trainingSystemId,
          courseId: params.filterSearch.courseId,
          departmentId: params.filterSearch.departmentId,
          majorId: params.filterSearch.majorId,
          status: params.filterSearch.status,
          classId: params.filterSearch.classId,
          orgId: user.orgId,
          name: params.filterSearch.name,
          code: params.filterSearch.code,
          consultantId: params.filterSearch.consultantId,
        })
        this.table.items = this.dataLists
        this.table.totalRows = this.total
      } catch (e) {
        this.showToast(`[${e.code}] ${e.message}`, 'XCircleIcon', 'danger')
      } finally {
        this.loading = false
      }
    },
    getTextStatus(id) {
      return STUDENT_CLASS_STATUSES.find(e => e.value === id).label
    },
    async onExportProvisionScorePdf(student) {
      this.loading = true
      try {
        const data = await this.exportProvisionScorePdf({ studentId: student.id })
        if (data) {
          const url = window.URL.createObjectURL(new Blob([data], { type: 'application/pdf' }))
          window.open(url, '_blank')
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'File không có dữ liệu',
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        }
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.loading = false
      }
    },
  },
}
</script>
<style>
.v-select.drop-up.vs--open .vs__dropdown-toggle {
  border-radius: 0 0 4px 4px;
  border-top-color: transparent;
  border-bottom-color: rgba(60, 60, 60, 0.26);
}

[data-popper-placement="top"] {
  border-radius: 4px 4px 0 0;
  border-top-style: solid;
  border-bottom-style: none;
  box-shadow: 0 -3px 6px rgba(0, 0, 0, 0.15);
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
